<template>
  <div style="margin-top: 20px">
    <div class="row" style="max-width: 99vw">
      <div class="col-md-2 mb-3">
        <div class="card" style="height: 100%; overflow-y: auto; overflow-x: clip">
          <div class="card-header">My Sensors</div>
          <div class="card-block">
            <TreeList :source="listType" ref="gatewayTreeList" />
          </div>
        </div>
      </div>
      <div class="col-md-2 mb-3" v-if="getCurrentState() >= StateEnum.SENSOR">
        <div class="card" style="height: 100%; overflow-y: auto; overflow-x: clip">
          <div class="card-header">Overview</div>
          <div class="card-block">
            <OverviewList />
          </div>
        </div>
      </div>
      <div class="mb-3" :class="getCurrentState() >= StateEnum.SENSOR? 'col-md-8' : 'col-md-10'">
.

        <ul class="nav nav-tabs px-0" id="myTab" role="tablist" v-if="getCurrentState() >= StateEnum.SENSOR">
          <li class="refresh-container">
            <button
              class="btn btn-primary btn-sm refresh-button"
              @click="refresh">
              <font-awesome-icon icon="sync"/>
            </button>
            <div class="auto-refresh-widget">
              <label>
                <input type="checkbox" @change="setAutoRefresh($event.target.checked)" />
                Auto Refresh
              </label>
            </div>
          </li>
          <li class="nav-item" role="presentation">
            <button
              :class="['nav-link', activeTab === 'chart' ? 'active' : '']"
              id="home-tab"
              @click="setActiveTab('chart')"
              type="button"
              role="tab"
              aria-controls="sensor_chart"
              :aria-selected="activeTab === 'chart'"
            >
              Chart
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              :class="['nav-link', activeTab === 'attributes' ? 'active' : '']"
              id="profile-tab"
              @click="setActiveTab('attributes')"
              type="button"
              role="tab"
              aria-controls="attributes"
              :aria-selected="activeTab === 'attributes'"
            >
              Attributes
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              :class="['nav-link', activeTab === 'sensorInfo' ? 'active' : '']"
              id="sensor-info-tab"
              @click="setActiveTab('sensorInfo')"
              type="button"
              role="tab"
              aria-controls="sensor_info"
              :aria-selected="activeTab === 'sensorInfo'"
              v-if="user_access_sensor_info == 'true'"
            >
              Sensor Info
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              :class="['nav-link', activeTab === 'gatewayControl' ? 'active' : '']"
              id="gateway-info-tab"
              @click="setActiveTab('gatewayControl')"
              type="button"
              role="tab"
              aria-controls="gateway_info"
              :aria-selected="activeTab === 'gatewayControl'"
            >
              Gateway Control
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              :class="['nav-link', activeTab === 'gatewayLocation' ? 'active' : '']"
              id="gateway-location-tab"
              @click="setActiveTab('gatewayLocation')"
              type="button"
              role="tab"
              aria-controls="gateway_location"
              :aria-selected="activeTab === 'gatewayLocation'"
              v-if="user_access_gateway_location=='true'"
            >
              Gateway Location
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              :class="['nav-link', activeTab === 'alarms' ? 'active' : '']"
              id="alarm-info-tab"
              @click="setActiveTab('alarms')"
              type="button"
              role="tab"
              aria-controls="alarm_info"
              :aria-selected="activeTab === 'alarms'"
            >
              Alarms
              <span
                v-if="alarmCountActive > 0"
                class="badge badge-pill badge-danger"
                >{{ alarmCountActive }}
              </span>
            </button>
          </li>
          <li v-if="holdingParmsExist" class="nav-item" role="presentation">
            <button
              :class="['nav-link', activeTab === 'holdingParms' ? 'active' : '']"
              id="holding-parms-tab"
              @click="setActiveTab('holdingParms')"
              type="button"
              role="tab"
              aria-controls="HP_info"
              :aria-selected="activeTab === 'holdingParms'"
            >
              Holding Parameters
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              :class="['nav-link', activeTab === 'msgCenter' ? 'active' : '']"
              id="msg-queue-tab"
              @click="setActiveTab('msgCenter')"
              type="button"
              role="tab"
              aria-controls="queued_info"
              :aria-selected="activeTab === 'msgCenter'"
            >
              Message Center
              <span
                v-if="pending_message_count > 0"
                class="badge badge-pill badge-yellow"
                >{{ pending_message_count }}
              </span>
            </button>
          </li>
        </ul>
        <div :class="[isLoading ? '' : 'd-none']" class="d-flex align-items-center justify-content-center" style="height: 95%;">
          <div class="spinner-border"/>
        </div>
        <div :class="[isLoading ? 'd-none' : '']" id="groupOverview" v-if="getCurrentState() === StateEnum.ROOT">
          <Summary
              title="Groups"
              :items="groups"
              level="group"
              childLabel="Group"
              childCountLabel="Gateways"
              childCountField="gateway_count"
          />
        </div>

        <div :class="[isLoading ? 'd-none' : '']" id="gatewayOverview" v-if="getCurrentState() === StateEnum.GROUP">
          <Summary
              title="Gateways"
              :items="gateways"
              level="gateway"
              childLabel="Gateway"
              childCountLabel="Sensors"
              childCountField="sensor_count"
          />
        </div>

        <div :class="[isLoading ? 'd-none' : '']" id="sensorOverview" v-if="getCurrentState() === StateEnum.GATEWAY">
          <Summary
              title="Sensors"
              :items="sensors"
              level="sensor"
              childLabel="Sensor"
              :pending-msg-icon="pendingMsgCount > 0"
          />
        </div>

        <div :class="[isLoading ? 'd-none' : '']" class="tab-content" id="myTabContent" v-if="getCurrentState() >= StateEnum.SENSOR">
          <div
            class="tab-pane fade"
            :class="{ 'show active': activeTab === 'chart' }"
            id="sensor_chart"
            role="tabpanel"
            aria-labelledby="chart-tab"
            v-if="getCurrentState() >= StateEnum.SENSOR"
          >
            <div class="card">
              <div class="card-header">
                <span style="color: red" v-if="has_unresponsive_data">
                  <font-awesome-icon icon="exclamation-triangle" />
                  Unresponsive sensor data is contained in the selected period. Click
                  <a style="text-decoration: underline; cursor: pointer" @click="showModal()"> here </a>
                  to see the data.
                </span>
              </div>
              <div class="card-block">
                <ApgChart
                  listType="group_gateway_sensor"
                />
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            :class="{ 'show active': activeTab === 'attributes' }"
            id="attributes"
            role="tabpanel"
            aria-labelledby="attribute-tab"
          >
            <gateway-attribute-list-user usage="list" v-if="getCurrentState() >= StateEnum.GATEWAY"/>
            <SensorAttributeList usage="list"  v-if="getCurrentState() >= StateEnum.SENSOR"/>
            <RegisterAttributeList usage="list" v-if="getCurrentState() >= StateEnum.SENSOR"/>
          </div>
          <div
            class="tab-pane fade"
            :class="{ 'show active': activeTab === 'sensorInfo' }"
            id="sensor_info"
            role="tabpanel"
            aria-labelledby="info-tab"
          >
            <div class="table-responsive">
            <table class="table table-bordered">
              <tr>
                <th>Attribute name</th>
                <th>Attribute value</th>
              </tr>
              <tr v-for="(myVal, myKey) in sensorInfo" :key="myKey">
                <td>{{ myKey }}</td>
                <td style="text-align: right">{{ myVal }}</td>
              </tr>
            </table>
            </div>
          </div>

          <div
            class="tab-pane"
            :class="{ 'show active': activeTab === 'gatewayLocation' }"
            id="gateway_location"
            role="tabpanel"
            aria-labelledby="info-tab"
          >
            <GatewayMap ref="map"/>
          </div>

          <div
            class="tab-pane fade"
            :class="{ 'show active': activeTab === 'gatewayControl' }"
            id="gateway_info"
            role="tabpanel"
            aria-labelledby="info-tab"
          >
            <gatewayReadingControl />
            <gateway-timing-table/>
          </div>

          <div
            class="tab-pane fade overflow-visible"
            :class="{ 'show active': activeTab === 'alarms' }"
            id="alarm_info"
            role="tabpanel"
            aria-labelledby="info-tab"
          >
            <alarm-list />
            <alarm-history />
          </div>
          <div
            class="tab-pane fade"
            :class="{ 'show active': activeTab === 'holdingParms' }"
            id="HP_info"
            role="tabpanel"
            aria-labelledby="info-tab"
          >
            <SensorHoldingRegisters />
          </div>
          <div
            class="tab-pane fade overflow-visible"
            :class="{ 'show active': activeTab === 'msgCenter' }"
            id="queued_info"
            role="tabpanel"
            aria-labelledby="info-tab"
          >
            <gatewayMessage />
          </div>
        </div>
      </div>
    </div>
    <BasicModal title="Choose groups to add" source="user_group"> </BasicModal>
    <ConfigureGatewayModal v-if="configureGatewayModal" @close="setConfigureGatewayModal(false)"/>
  </div>
</template>

<script>
import {onUnmounted, ref} from "vue";
import TreeList from "@/components/shared/lists/TreeList.vue";
import OverviewList from "../shared/lists/overview-list.vue";
import ApgChart from "@/components/shared/charts/ApgChart";
import GatewayReadingControl from "@/components/shared/lists/gatewayControlReading-list";
import BasicModal from "../shared/modals/basic-modal";
import { useStore } from "vuex";
import { onMounted, computed, nextTick } from "vue";
import SensorAttributeList from "../shared/lists/sensor-attribute-list";
import RegisterAttributeList from "../shared/lists/register-attribute-list";
import AlarmList from "@/components/shared/lists/alarm-list";
import AlarmHistory from "@/components/shared/lists/alarm-history";
import gatewayMessage from "@/components/shared/lists/gateway_message";
import SensorHoldingRegisters from "@/components/shared/lists/sensor-holdingRegisters-list";
import GatewayMap from "@/components/shared/charts/GatewayMapMyView";
import GatewayAttributeListUser from "@/components/shared/lists/gateway-attribute-list-user.vue";
import socketInstance from "@/services/Socket";
import Summary from "@/components/shared/Summary.vue";
import ConfigureGatewayModal from "@/components/shared/modals/ConfigGatewayModal.vue";
import GatewayTimingTable from "@/components/shared/lists/gatewayTimingTable.vue";

export default {
  components: {
    GatewayTimingTable,
    GatewayAttributeListUser,
    TreeList,
    OverviewList,
    ApgChart,
    GatewayReadingControl,
    BasicModal,
    SensorAttributeList,
    AlarmList,
    AlarmHistory,
    SensorHoldingRegisters,
    gatewayMessage,
    GatewayMap,
    RegisterAttributeList,
    Summary,
    ConfigureGatewayModal,
  },
  setup() {
    const store = useStore();
    const listType = "group_gateway_sensor";

    const showModal = () => {
      store.commit("chart/showChartModal", { show_modal: true });
    };

    let gatewayTreeList = ref(null);

    // Add new sensor data to the chart
    socketInstance.registerListener("refresh", () => {
      console.log('Received new sensor data');
      setTimeout(() => {
        if (getCurrentState() >= StateEnum.SENSOR) {
          refresh(false);
        }
      }, 5000);
    });

    const refresh = async (clicked=true) => {
      if (store.state.settings.is_loading || (!store.state.settings.auto_refresh && !clicked)) return;
      console.log('Refreshing data');
      store.commit('settings/setIsLoading', true);
      let sensor_id = store.state.sensor.id;
      if (sensor_id != null) {
        await store.dispatch('sensor/findInfo', {
          id: sensor_id,
          listType: listType,
          registerAddress: store.state.register.address
        });
        await store.dispatch('sensor/findInfoOverview', {id: sensor_id});
      }
      store.commit('settings/setIsLoading', false);
    };

    onMounted(async () => {
      await store.dispatch("group/findAllForMe", { listType });
      store.commit('MyView/saveState');
    });

    // navigating away from this page clears the tree selection
    onUnmounted(() => {
      store.commit("MyView/clearSelected", {level: "sensor"});
      store.commit("MyView/clearSelected", {level: "gateway"});
      store.commit("MyView/clearSelected", {level: "group"});
    });

    // State control to determine which view to display
    const StateEnum = Object.freeze({
      ROOT: 1,
      GROUP: 2,
      GATEWAY: 3,
      SENSOR: 4,
    });

    const getCurrentState = () => {
      const { group, gateway, sensor } = store.state.MyView.selected || {};
      if (!group?.id && !gateway?.id && !sensor?.id) return StateEnum.ROOT;
      if (group?.id && !gateway?.id && !sensor?.id) return StateEnum.GROUP;
      if (group?.id && gateway?.id && !sensor?.id) return StateEnum.GATEWAY;
      if (group?.id && gateway?.id && sensor?.id) return StateEnum.SENSOR;
    };

    const groups = computed(() => store.state.MyView.data?.group_gateway_sensor || []);

    const gateways = computed(() => {
      const selectedId = store.state.MyView.selected.group?.id;
      const group = groups.value.find(g => g.id === selectedId);
      return group?.nodes || [];
    });

    const sensors = computed(() => {
      const selectedId = store.state.MyView.selected.gateway?.id;
      const gateway = gateways.value.find(g => g.id === selectedId);
      let sensors = gateway?.nodes;
      for (let sensor of sensors) {
        if (sensor.last_update == null) {
          sensor.last_update = gateway.last_update;
        }
      }
      return sensors
    });

    const pendingMsgCount = computed(() => {
      const selectedGatewayId = store.state.MyView.selected.gateway?.id;
      const gateway = gateways.value.find(g => g.id === selectedGatewayId);
      return gateway?.message_queue_count ?? 0;
    });

    const alarmCountActive = computed(() => {
      let gatewayId = store.state.MyView.selected.gateway?.id;
      let gateway = gateways.value.find(g => g.id === gatewayId);
      return gateway?.alarm_count_active ?? 0;
    });

    // history hijack
    window.addEventListener('popstate', function(event) {
      if (event.state && event.state.vuexState) {
        store.commit('MyView/loadState', event.state.vuexState);
      }
    });

    const activeTab = ref('chart');  // default tab
    const map = ref(null);
    const setActiveTab = (tab) => {
      activeTab.value = tab;
      if (tab === 'gatewayLocation') {
        nextTick(() => {
          map?.value?.resizeMap();
        });
      }
    };

    return {
      listType,
      sensorInfo: computed(() => store.state.apgList.List["sensor_info"]),
      readingsInfo: computed(() => store.state.sensor.readings),
      minValue: computed(() => store.state.sensor.min_value),
      maxValue: computed(() => store.state.sensor.max_value),
      pending_message_count: computed(() => store.state.apgList.List["gateway_queued_message"].length),
      messageQueue: computed(() => store.state.apgList.List["gateway_queued_message"]),
      has_unresponsive_data: computed(() => store.state.chart.has_unresponsive_data["group_gateway_sensor"]),
      user_access_gateway_location: computed (() => store.state.auth.user?.user?.Info?.accessToGPSLocation),
      user_access_sensor_info: computed (() => store.state.auth.user?.user?.Info?.myViewSensorInfoView),
      user_access_holding_params: computed (() => store.state.auth.user?.user?.Info?.extendedHoldingParameters),
      isLoading: computed(() => store.state.settings.is_loading),
      showModal,
      gatewayTreeList,
      refresh,
      StateEnum,
      getCurrentState,
      groups,
      gateways,
      sensors,
      alarmCountActive,
      pendingMsgCount,
      configureGatewayModal: computed(() => store.state.ConfigWizard.showModal),
      setConfigureGatewayModal: (bool) => store.commit("ConfigWizard/setShowModal", bool),
      setAutoRefresh: (bool) => store.commit("settings/setAutoRefresh", bool),
      setActiveTab,
      activeTab,
      map,
      holdingParmsExist: computed(() => store.state.sensor.holding_registers?.length > 0),
    };
  },
};
</script>

<style scoped>
table {
  margin: 20px;
  width: 400px;
}

.badge-danger {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  0%, 100% {
    background-color: var(--color-bg);
  }
  50% {
    background-color: red;
  }
}

.refresh-container {
  position: relative;
  display: inline-block;
  margin: 10px;         /* matches your existing margin on the button */
}

.auto-refresh-widget {
  position: absolute;
  top: 50%;              /* Middle of the button vertically */
  left: 100%;            /* Immediately to the right edge of the button */
  transform: translateY(-50%);
  width: 0;
  overflow: hidden;
  background: var(--color-bg-sec);
  color: var(--color-txt);
  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 2px 0;
  transition: width 0.3s ease;
  z-index: 10;
}

.refresh-container:hover .auto-refresh-widget {
  width: 130px;  /* Adjust this based on how wide the label needs to be */
  padding: 2px 8px; /* Add side padding once expanded */
}

.auto-refresh-widget label {
  white-space: nowrap;
  font-weight: normal;  /* Typically for checkboxes, but optional */
}

.auto-refresh-widget input[type="checkbox"] {
  margin-right: 4px;
}
</style>
